<template>
  <vx-card no-body>
    <b-nav class="mb-0" tabs>
      <b-nav-item
        v-for="(page, idx) in pages"
        :key="idx"
        :to="page.to"
        :active="isRouteActive(page.to)"
      >
        <div class="d-flex align-items-center my-1">
          <feather-icon size="18" :icon="page.icon" class="mr-1" />
          <h4 class="mb-0">
            {{ page.label }}
          </h4>
        </div>
      </b-nav-item>
    </b-nav>
    <b-card-body>
      <slot />
    </b-card-body>
  </vx-card>
</template>

<script>
import { BCardBody, BNav, BNavItem } from 'bootstrap-vue'
import VxCard from '@/components/VxCard'
import { casePages } from '@/views/cases/casesService'
import { getRouteIdParam, isRouteActive } from '@/router'

export default {
  name: 'CaseContainer',
  components: {
    BNav,
    BNavItem,
    BCardBody,
    VxCard
  },
  setup () {
    const { pages } = casePages(getRouteIdParam())

    return {
      pages,
      isRouteActive
    }
  }
}
</script>
